import React from "react"
import Layout from "../components/Layout"
import styles from "./loppet.module.css"
import Content from "../components/Content"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import Footer from "../components/Footer"

export default function Loppet() {
  return (
    <>
      <SEO
        title="Loppet"
        description="Black Friday Trail Run, 29 November 2024, Tuve, Göteborg"
      />

      <Layout>
        <Content>
          <p>
            Black Friday Trailrun är en idé som uppkom i samband med den
            köphysteri som råder denna dag. Vi orienterare förknippar Black
            Friday med nattorientering med pannlampa. Hur skall vi sprida denna
            idé bland flera? Ett traillopp med fokus på gemenskap och glädje
            utan resultathysteri förstås. Perfekt som en alternativ AW med
            jobbet eller att träffa vänner som du inte sett på ett tag. Istället
            för att spendera pengarna på handlande på nätet så kan du bidra med
            en slant till vår ungdomsverksamhet, dessutom få en härlig
            upplevelse i mörkret.
          </p>
          <dl className={styles.facts}>
            <div>
              <dt>När</dt>
              <dd>
                <p>29 November 2024</p>
                <ul className={styles.extra}>
                  <li>
                    Barn: start <em>18:00</em>
                  </li>
                  <li>
                    9 km, Snabba gruppen: start <em>19:00</em>
                  </li>
                  <li>
                    9 km, Lagom-gruppen: start <em>19:10</em>
                  </li>
                </ul>
              </dd>
            </div>
            <div>
              <dt>Var</dt>
              <dd>
                Hisingsparken, Göteborg
                <p className={styles.extra}>
                  Start och mål Tolereds AIK:s klubbstuga vid Tuvevallen.
                  Kommunala P-platser i anslutning till start och mål. Buss 17:s
                  ändhållplats Hinnebäcksgatan cirka 250 meter.
                </p>
              </dd>
            </div>
            <div>
              <dt>Bana</dt>
              <dd>
                Små till mycket små stigar i tidvis mycket kuperad terräng,
                strax under 9 km.
                <p className={styles.extra}>
                  Banan är markerad med reflexer &mdash; glöm inte pannlampa!
                  Flaggvakter vid vissa korsningar och passager. Se{" "}
                  <Link to="/banan">sidan om Banan</Link> för detaljerad
                  information.
                </p>
                <p className={styles.extra}>
                  <em>
                    Nytt för i år är att vi även har en kortare bana för barn
                  </em>
                  : 2,5 km reflexbana. Precis som den långa banan behövs
                  pannlampa och skor som tål novemberblöta skogsstigar.
                  Rekommenderad upp till 13 år.
                </p>
                <p className={styles.extra}>
                  Den 1 oktober klockan 19:00 kommer vi anordna en provlöpning
                  av vuxenbanan. Mer{" "}
                  <a href="https://www.facebook.com/events/519752963984071">
                    info och anmälan på Facebook
                  </a>
                  .
                </p>
              </dd>
            </div>
            <div>
              <dt>Anmälan</dt>
              <dd>
                Se <Link to="/anmalan">anmälningssidan</Link>.
                <p>
                  Är ni ett kompis- eller företagsgäng som vill springa? Grupper
                  med fem eller fler får 10% rabatt på anmälan till sista
                  oktober.{" "}
                  <a href="mailto:info@blackfridaytrailrun.se">
                    Hör av er till oss
                  </a>{" "}
                  om ni har frågor.
                </p>
              </dd>
            </div>
            <div>
              <dt>Servering</dt>
              <dd>Mat och dryck efter målgång ingår i anmälningsavgiften!</dd>
            </div>
            <div>
              <dt>Service</dt>
              <dd>
                Toalett, första hjälpen och väskförvaring vid start och mål (för
                värdesaker ansvaras ej).
              </dd>
            </div>
            <div>
              <dt>Tidtagning</dt>
              <dd>Elektronisk tidtagning med chip.</dd>
            </div>
            <div>
              <dt>Kontakt</dt>
              <dd>
                Du når oss på{" "}
                <a href="mailto:info@blackfridaytrailrun.se">
                  info@blackfridaytrailrun.se
                </a>
                .
                <p className={styles.extra}>
                  Följ oss gärna på{" "}
                  <a href="https://www.facebook.com/blackfridaytrailrun">
                    Facebook
                  </a>{" "}
                  och{" "}
                  <a href="https://www.instagram.com/black_friday_trailrun/">
                    Instagram
                  </a>{" "}
                  också!
                </p>
              </dd>
            </div>
          </dl>
          <Footer />
        </Content>
      </Layout>
    </>
  )
}

import React from "react"
import styles from "./Footer.module.css"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="mb-4">
        Arrangeras av <a href="https://toleredsaik.se/">Tolereds AIK</a> i
        samarbete med
      </div>
      <div className={styles.sponsors}>
        <a href="https://nordicwellness.se/" className={styles.mareld}>
          <img src="nordic-wellness.png" alt="Nordic Wellness" />
        </a>
      </div>
      <div className={styles.sponsors}>
        <a href="https://www.gococo.se/se/" className={styles.sponsors}>
          <img src="gococo.png" className={styles.sponsor} alt="Gococo" />
        </a>
        <a href="https://walkfeeling.se/" className={styles.sponsors}>
          <img
            src="walkfeeling.png"
            className={styles.sponsor}
            alt="Walkfeeling"
          />
        </a>
        <a href="https://www.honokaka.se/" className={styles.sponsors}>
          <img
            src="akes.png"
            className={styles.sponsor}
            alt="Åkes Äkta Hönökakor"
          />
        </a>
        <a href="https://www.lampster.se/" className={styles.sponsors}>
          <img
            src="lampster.jpg"
            className={styles.sponsor}
            alt="Lampster.se"
          />
        </a>
      </div>
      <div className={styles.sponsors}>
        <a
          href="https://www.smartinthedark.com/"
          className={styles.sponsorWide}
        >
          <img src="smart-in-the-dark.jpg" alt="Smart in the Dark" />
        </a>
        <a
          href="https://www.facebook.com/blomsterrikettuve/"
          className={styles.sponsor}
        >
          <img src="blomsterriket.jpg" alt="Blomsterriket Tuve Torg" />
          Tuve Torg
        </a>
        <a href="https://mohlinskonditori.se/" className={styles.sponsors}>
          <img
            src="mohlins.png"
            className={styles.sponsor}
            alt="Mohlins konditori"
          />
        </a>
      </div>
    </footer>
  )
}
